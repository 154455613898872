/* @flow */

module.exports = {
  "LOCALE": {
    "ISO": {
      "639-1": "en",
      "3166-1": "GB",
      "IETF": "en-GB",
    },
    "CURRENCY": "EUR",
    "CURRENCY_SHORT": "€",
    "POINTS": "points",
    "TYPEKIT_ID": "thm8nca",
  },
  "META": {
    "GOOGLE_SITE_OWNER": "JE5RJH9mE7LLsEhBFrF7e0T3poF_BesW7P3wibE8XwA",
  },
  "LOGO": {
    "ALT": "Scandic",
    "TITLE": "Go to home page",
  },
  "POINTS_NOTICE": "Scandic Friends Shop is temporarily operating with point redemption restrictions, due to the current situation regarding Covid-19. Each member can therefore redeem a maximum of %limit per month.",
  "POINTS_NOTICE_REMAINING": "Scandic Friends Shop is temporarily operating with point redemption restrictions, due to the current situation regarding Covid-19. Each member can therefore redeem a maximum of %limit per month. So far, you've redeemed %spent. The limit will reset at %resetsAt.",
  "HOMEVIEW": {
    "HERO_ALT": "Scandic",
    "POPULAR_BRANDS": "Popular brands",
    "POPULAR_CATEGORIES": "Popular categories",
    "POPULAR_PRODUCTS": "Popular products",
    "RECENTLY_VIEWED_PRODUCTS": "Recently viewed products",
    "SEE_MORE": "View more",
    "BECOME_CUSTOMER": "Become a member",
    "AFFORDABLE_PRODUCTS": {
      "HEADING": "Products below %points",
      "TEXT": "Our favourite products – based on your current point balance.",
    },
  },
  "LOGIN": {
    "TITLE": "Login",
    "EMAIL": "Email address",
    "CUSTOMER_NUMBER": "Customer id you'd like to login as",
    "PASSWORD": "Password",
  },
  "ACCOUNT": {
    "LOGIN": "Log in",
    "LOGOUT": "Log out",
    "MY_ACCOUNT": "My account",
    "USER": "Member",
    "MEMBERSHIP": "Membership",
    "MEMBER_NUMBER": "Membership No",
    "LAST_REGISTERED_POINTS": "Recently earned points",
    "POINTS_READ_MORE": "You can read more about your Scandic Friends points at",
    "POINTS_READ_MORE_LINK": "https://www.scandichotels.com/scandic-friends",
    "SHIPPING_ADDRESS": "Delivery address",
    "ELAPSED_POINTS": "Expiring points",
    "ELAPSING_POINTS_INFO": "You have %points points that will expire %date (%dateRelative)",
    "EDIT_INFO_TEXT": "You can edit your customer details at scandichotels.com",
    "EDIT_INFO": "Edit",
    "EDIT_LINK": "https://www.scandichotels.com/scandic-friends/my-profile/spend-your-points/update-profile",
    "WISHLIST": "Wishlist",
    "EMAIL": "E-mail",
    "BALANCE": "Balance",
    "DATE": "Date",
    "SEE_ALL": "View all",
    "EDIT": "Edit",
    "ONE_DAY_LEFT": "1 day left",
    "DAYS_LEFT": "%daysLeft days",
  },
  "ORDER_HISTORY": {
    "TITLE": "Order history",
    "OPEN": "Open",
    "ORDER_NO": "Order id",
    "ORDER_DATE": "Order date",
    "ORDER_CONTENT": "Products",
    "TOTAL_INC_SHIPPING": "Grand total",
    "PAYMENT": "Payment",
    "SHIPPING": "Shipping",
    "DELIVERED_TO": "Delivered to",
    "OTHER_DETAILS": "Extra info",
    "ORDER_STATUS": "Status",
    "PAGINATION": "Page %current / %total",
    "NO_TRANSACTIONS": "You don't have any previous transactions.",
    "EXPLORE_ASSORTMENT": "Explore assortment",
    "DISCOUNT": "Discount",
    "STATUS": {
      "CANCELED": "Canceled",
      "CLOSED": "Closed",
      "COMPLETE": "Complete",
      "DEFAULT": "...",
      "FRAUD": "Suspected Fraud",
      "HOLDED": "On hold",
      "PAYMENT_REVIEW": "Payment review",
      "PAYPAL_CANCELED_REVERSAL": "PayPal canceled reversal",
      "PAYPAL_REVERSED": "PayPal reversed",
      "PENDING": "Pending",
      "PENDING_PAYMENT": "Pending payment",
      "PEDNING_PAYPAL": "Pedning PayPal",
      "PROCESSING": "Processing",
    },
  },
  "ALL_PRODUCTS": {
    "TITLE": "All products",
  },
  "OCF": {
    "TITLE": "Filter",
    "SHOW_RESULT": "Apply filter",
    "SHOW_RESULT_TOTAL": "Show %total filtered products",
    "CATEGORY": "Category",
    "CLEAR_ALL": "Clear filter",
    "NO_ACTIVE_FILTERS": "You have no active filters",
    "PRICE_RANGE": "Price (%range)",
    "RESET_PRICE_FILTER": "Clear",
    "PRICE_FROM": "From",
    "PRICE_TO": "To",
    "POINTS": "%price points",
    "PRICE": "%price €",
    "FILTER_BY_POINT": "Filter by price in points",
    "FILTER_BY_PRICE": "Filter by price",
    "CODES": {
      "CATEGORY": "Category",
      "MANUFACTURER": "Brand",
      "PRICE": "Price",
    },
  },
  "WISHLIST": {
    "TITLE": "Wishlist",
    "YOU_CAN_AFFORD_THIS": "You have enough points!",
    "NOT_ENOUGH_POINTS": "You don't have enough points",
    "REMOVE_FROM_WISHLIST": "Remove from wishlist",
    "BACK": "Go to My Account",
    "EMPTY": "You have no items in your wishlist.",
    "EXPLORE_ASSORTMENT": "Explore assortment",
  },
  "ADDRESS": {
    "FIRSTNAME": "First name",
    "LASTNAME": "Last name",
    "STREET_1": "Street address 1",
    "STREET_2": "Street address 2",
    "POSTCODE": "Postal code",
    "CITY": "City",
    "COUNTRY": "Country",
    "EMAIL": "E-mail",
    "TELEPHONE": "Phone number",
    "MISSING": "Address missing",
    "NAME_MISSING": "Name is missing",
    "STREET_MISSING": "Street address is missing",
    "POSTCODE_MISSING": "Postal code is missing",
    "CITY_MISSING": "City is missing",
    "TELEPHONE_MISSING": "Phone number is missing",
    "EMAIL_MISSING": "E-mail is missing",
    "COUNTRY_MISSING": "Country is missing",
    "BILLING_ADDRESS": "Billing address",
    "SHIPPING_ADDRESS": "Delivery address",
  },
  "OR": "or",
  "VALUE": "Value",
  "LANGUAGE": {
    "DELIVER_TO": "Deliver to",
  },
  "CART": {
    "EMPTY": "Your cart is empty",
    "CTA": "Go to checkout",
    "EDIT": "Edit cart contents",
    "TO_PAY": "Total amount to pay",
    "INCREMENT": "Increase quantity",
    "DECREMENT": "Decrease quantity",
    "QUANTITY": "Quantity",
    "OPEN_CART": "Open cart",
    "CONTINUE_SHOPPING": "continue shopping",
    "PRODUCTS": "Products",
    "SHIPPING": "Shipping (%shippingMethod)",
    "SUBTOTAL": "Subtotal",
    "GRANDTOTAL": "Grand total",
    "YOUR_BALANCE": "Current point balance",
    "SUMMARY": "Summary",
    "TO_CHECKOUT": "Go to checkout",
    "MOVE_TO_WISHLIST": "Move to wishlist",
    "REMOVE_PRODUCT": "Remove item",
    "REMOVE_APPROVE": "Yes, remove this item",
    "CONFIRM_TEXT": "Are you sure you'd like to remove '%itemName' from  your cart?",
    "DISCOUNT": "Discount",
  },
  "CONFIRM_DIALOG": {
    "I_UNDERSTAND": "I understand",
    "CANCEL": "Cancel",
  },
  "REJECT_REASONS": {
    "POINT_PAYMENT_REQUIRED": {
      "NO_AVAILABLE_POINT_PAYMENT": "Chosen products require payment in points, your point balance currently not sufficient.",
      "BALANCE": "The lowest available point value (%requested) for the cart exceeds your point balance of %balance.",
      "TOTAL_LIMIT": "The lowest available point value (%requested) for the cart is more than the remaining points you have left to spend this month, thus exceeding the limit of %limit per month. You can shop for your points again next month.",
      "ORDER_LIMIT": "The lowest available point value (%requested) for the cart exceeds the maximum point value for an order. Please adjust the contents of your cart.",
    },
    "POINT_PAYMENT_OPTIONAL": {
      "NO_AVAILABLE_POINT_PAYMENT": "The point balance is too low to pay with points but you can pay with card.",
      "REMAINING_POINTS_MAXED_OUT": "You've reached the limit of %limit points per month. You can shop for your points again next month or pay with card.",
    },
  },
  "CHECKOUT": {
    "TITLE": "Checkout",
    "NEED_HELP": "Need help? We're here for you!",
    "CONTACT_CUSTOMER_SERVICE": "Contact customer service",
    "TO_PAYMENT": "Go to payment",
    "CHOOSE_PAYMENT_METHOD": "Proceed to payment",
    "POINTS_TITLE": "Pay using points",
    "POINTS_TOOLTIP": "Scandic Friends Shop accepts payment using points, a credit card or a combination of both. You can choose the amount of points you would like to redeem by adjusting the slider. Any remaining amount will be charged to your credit or debit card. Adjust the slider all the way to the right to pay the order entirely using points or use all your remaining points (whichever is the lesser), or all the way to the left to pay the order entirely using a credit or debit card.",
    "POINTS_TOOLTIP_POSTFIX": "You have %remaining points remaining of this month's limit of %limit points.",
    "POINTS_BALANCE": "You currently have %points. What amount of points would you like to spend?",
    "AMOUNT_TO_PAY": "Amount to be withdrawn",
    "POINTS": "Points",
    "PAY_WITH_CARD": "Pay using card",
    "EDIT": "Change",
    "PAYMENT_AND_DELIVERY": "Payment & Delivery",
    "DISTRIBUTION": "Distribution",
    "SENT_TO": "To be sent  to",
    "ADDRESSES_TITLE": "Address",
    "EDIT_ADDRESS": "Edit address",
    "ADDRESS_MINIMIZE": "Minimise",
    "CONFIRM_ORDER": "Confirm order",
    "CONTINUE_SHOPPING": "Continue shopping",
    "BACK_TO_STORE": "Back to the store",
    "PAYMENT_TITLE": "Payment",
    "CARD_PAYMENT": "Card payment",
    "I_ACCEPT_THE": "I accept",
    "TERMS": "the terms for this purchase",
    "TERMS_LINK": "terms",
    "MODE_OF_DELIVERY": "* delivered using %shippingMethod",
    "PHYSICAL_DELIVERY": "Your order will be delivered by %shippingMethod. \nExpect delivery within 2-4 business days.",
    "VIRTUAL_DELIVERY": "Your order will be delivered digitally via email.",
    "VERIFY_ACCOUNT": "Verify account",
    "ACCOUNT_VERIFIED": "Account verified",
    "VERIFY_ACCOUNT_DESCRIPTION": "This verification is needed for additional security.",
    "DISCOUNT_CODE": {
      "HEADER": "Discount code",
      "CHECKBOX_LABEL": "I have a discount code",
      "APPLY": "Apply",
      "REMOVE": "Remove",
    },
    "DISCOUNT": "Discount (%discount)",

    "PAYMENT_STEP": {
      "CREATING": "Creating order…",
      "ALLOCATING": "Allocating points…",
      "COMPLETE": "Completing…",
    },

    "ADDRESS": {
      "ADDRESS": "Address",
      "SHIPPING": "Delivery address",
      "BILLING": "Address on receipt",
      "SHIP_TO_OTHER_ADDRESS": "Use alternative delivery address",
      "CONTACT_INFO": "Contact details",
      "EMPTY_ADDRESS": "The address is not complete, please update",
    },
    "STEPS": {
      "CART": {
        "SHORT": "Cart",
        "LONG": "Cart",
      },
      "CHECKOUT": {
        "SHORT": "Checkout",
        "LONG": "Checkout",
      },
    },
  },
  "PAYMENT": {
    "TITLE": "How would you like to pay?",
    "USE_PAY": "Pay now",
    "USE_CARD": "Pay with card",
    "USE_SAVED_CARD": "Pay with saved card",
  },
  "PAYMENT_METHODS": {
    "APPLE_PAY": "pay",
  },
  "CUSTOMER_SERVICE": {
    "TEXT": "FAQ",
    "LINK": "/customer-service",
  },
  "SUCCESS": {
    "TITLE": "Order completed",
    "SUB_TITLE": "Order id %number",
    "NOT_FOUND_TITLE": "No order found.",
    "NOT_FOUND_SUBTITLE": "Please contact customer service if this is incorrect.",
    "STEPS": {
      "TITLE": "Thank you for your order!",
      "HEADING": "Order confirmed",
      "WHAT_NOW": "What comes next?",
      "LABEL": "Your order",
      "ORDER_ID": "Order id %id",
      "QUESTIONS": {
        "HEADING": "Got a question for customer service?",
        "LABEL": "We're eager to help",
        "URL": "/faq",
      },
      "NORMAL": {
        "1": {
          "HEADING": "Confirmation",
          "TEXT": "An order confirmation will be sent to %email shortly.",
        },
        "2": {
          "HEADING": "Processing",
          "TEXT": "Your order will be dispatched as soon as all products are available for delivery. Normally, this happens within 24h.",
        },
        "3": {
          "HEADING": "Shipping",
          "TEXT": "When your items have been picked and packed the will be dispatched to the shipping agent. As soon as the parcel is registered, you will receive a shipping confirmation via email, containing a tracking link with which you'll be able to follow the parcel on its journey.",
        },
        "4": {
          "HEADING": "Delivery",
          "TEXT": "Delivery is done to the pickup point closest to the address you have provided. As soon as the parcel is ready for pickup you will be notified via text message and/or email. The parcel will remain at the pickup point for a maximum of 14 days before being returned to us.",
        },
      },
      "MIXED": {
        "1": {
          "HEADING": "Confirmation",
          "TEXT": "An order confirmation, as well as the voucher codes you've ordered, will be sent to %email shortly.",
        },
        "2": {
          "HEADING": "Processing",
          "TEXT": "Your order will be dispatched as soon as all products are available for delivery. Normally, this happens within 24h.",
        },
        "3": {
          "HEADING": "Shipping",
          "TEXT": "When your items have been picked and packed the will be dispatched to the shipping agent. As soon as the parcel is registered, you will receive a shipping confirmation via email, containing a tracking link with which you'll be able to follow the parcel on its journey.",
        },
        "4": {
          "HEADING": "Delivery",
          "TEXT": "Delivery is done to the pickup point closest to the address you have provided. As soon as the parcel is ready for pickup you will be notified via text message and/or email. The parcel will remain at the pickup point for a maximum of 14 days before being returned to us.",
        },
      },
      "VIRTUAL": {
        "1": {
          "HEADING": "Confirmation",
          "TEXT": "An order confirmation will be sent to %email shortly.",
        },
        "2": {
          "HEADING": "Digital delivery",
          "TEXT": "Any voucher codes you've ordered are displayed here on the receipt page. We'll also send them to %email shortly. If you haven't received an email within one hour of placing the order, we ask you to kindly double check your spam filter before contacting customer service.",
        },
      },
    },
  },
  "OLD_BROWSER": {
    "INFO": "Hi! You are using an outdated browser. Please. <a href=\"http://outdatedbrowser.com\" rel=\"noopener\" target=\"blank\">update your browser</a> to be able to use YourChristmasPresent.com",
  },
  "CATEGORY": {
    "EMPTY": "This category contains no products",
    "LOAD_MORE": "View more",
    "PRODUCTS_IN_CATEGORY_TITLE_SINGULAR": "Viewing 1 product",
    "PRODUCTS_IN_CATEGORY_TITLE_PLURAL": "Viewing %itemsCount products",
    "PRODUCTS_IN_CATEGORY_TITLE_EMPTY": "Viewing products",
  },
  "POPULAR": {
    "POPULAR_PRODUCTS": "Popular products",
  },
  "404": {
    "TITLE": "Sorry, this page could not be found.",
    "HEADING": "Sorry, this page could not be found.",
    "SUBHEADING": "Would you like to go back to the <a href=\"/\">start page</a>?",
  },
  "SERVER_ERROR": {
    "TITLE": "Server error",
    "HEADING": "Server error: 500",
    "SUBHEADING": "We apologise – please return in a while.",
    "TEXT": "<strong>Need to get in touch?</strong><br/>Contact us via our <a href=\"mailto:info@awardit.com\">info@awardit.com</a>.",
  },
  "SEARCH": {
    "TITLE_PREFIX": "Search: ",
    "PLACEHOLDER": "What are you looking for?",
    "PRODUCT_IN_SEARCH_RESULT": "Found 1 product",
    "PRODUCTS_IN_SEARCH_RESULT": "Found %itemsCount products",
    "SEARCH_RESULTS_FOR": "Search results for",
    "NEW_SEARCH": "Search again",
    "NO_RESULTS": "No matching products found",
  },
  "HEADER": {
    "BACK_TO": "Back to %url",
    "POINTS_SHOP": "Scandic Friends Shop",
    "CLOSE_MENU": "Close menu",
    "BACK": "Back",
    "SEARCH": "Search",
    "CATEGORIES": "Categories",
    "CLOSE_CATEGORIES": "Close categories",
    "BRANDS": "Brands",
    "CLOSE_BRANDS": "Stäng varumärken",
    "WISHLIST": "Wishlist",
    "ACCOUNT": "My account",
    "CART": "Checkout",
    "LOGOUT": "Log out",
    "LOGIN": "Log in",
    "LOGGING_IN": "Loading...",
    "ALL_PRODUCTS": "All products",
    "LEVEL": "Level",
    "TIER": {
      "RED": "Red",
      "TOP": "Top",
      "DEFAULT": "1",
    },
    "DELIVER_TO": "Deliver to",
    "HOW_DOES_IT_WORK": "How does it work?",
    "GO_TO_SCANDIC": "Go to scandichotels.com",
    "GO_TO_SCANDIC_LINK": "https://scandichotels.com",
    "MY_ACCOUNT": "My account",
  },
  "FOOTER": {
    "CATEGORIES": "Categories",
    "LINKS": {
      "TITLE": "Links",
      "START": "Home",
      "CATEGORIES": "Categories",
      "BRANDS": "Brands",
      "SEARCH": "Search",
      "WISHLIST": "Wishlist",
      "ACCOUNT": {
        "TEXT": "My account",
        "LINK": "/account",
      },
      "TERMS": {
        "TEXT": "Terms",
        "LINK": "/terms",
      },
      "PRIVACY_POLICY": {
        "TEXT": "Privacy policy",
        "LINK": "/privacy-policy",
      },
      "ABOUT": {
        "TEXT": "About Scandic Friends",
        "LINK": "https://www.scandichotels.com/scandic-friends",
      },
    },
    "PAYMENT_METHODS": "Payment methods",
    "ABOUT": {
      "HEADING": "About Scandic Friends Shop",
      "TEXT": "Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam porta sem malesuada magna mollis euismod.",
    },
    "NEED_HELP": {
      "TITLE": "In need of assistance?",
      "CONTACT": "Customer service",
      "DATE": "Available business days 10-12",
      "COOKIES": "Manage cookies",
    },
    "LEGAL": {
      "FAQ": "Frequently asked questions",
    },
    "STAY_IN_TOUCH": {
      "TITLE": "Stay in touch",
      "FACEBOOK": "Facebook",
      "INSTAGRAM": "Instagram",
      "YOUTUBE": "Youtube",
      "TWITTER": "Twitter",
      "NEWSLETTER": "Subscribe to newsletter",
    },
    "PRIVACY_POLICY": "Privacy policy",
    "TERMS": "Terms & Conditions",
    "COPYRIGHT": "© Copyright Scandic / <a href=\"https://awardit.com\" target=\"_blank\" rel=\"noopener\">Awardit</a>",
  },
  "BREADCRUMBS": {
    "HOME_URL": "/",
    "HOME": "Home",
    "SEARCH": "Search results for \"%query\"",
  },
  "FILTER": {
    "ALL_FILTERS": "All filters",
    "FILTER_BY_POINT": "Filter by price in points",
    "FILTER_BY_PRICE": "Filter by price",
    "POINT_RANGE": "%min - %max points",
    "PRICE_RANGE": "%min - %max €",
    "SORT": "Sort",
    "NUM_MATCHES": "Viewing %count matches",
    "CLEAR_ALL": "Clear filters",
    "SORT_DOWN": "↓",
    "SORT_UP": "↑",
    "SORT_POSITION": "Best match",
    "SORT_PRICE_DESC": "Price ↓",
    "SORT_PRICE_ASC": "Price ↑",
    "SORT_NAME": "A-Z",
  },
  "PRODUCT": {
    "ADD_TO_CART": "Add to cart",
    "DESCRIPTION": "Product description",
    "OTHERS_ALSO_LIKED": "Other items you might be interested in",
    "OPTION_DROPDOWN_PLACEHOLDER": "Please select",
    "LOGIN_TO_PURCHASE": "Please log in, in order to purchase",
    "CHANGE_IMAGE": "Change image",
    "GO_TO_MANUFACTURER": "View all products from this brand",
    "OUT_OF_STOCK": "Out of stock",
  },
  "STRIPE": {
    "CARD_NUMBER": "Card number",
    "MM/YY": "MM/YY",
    "CVC": "CVC",
    "ERROR": {
      "CARD_NUMBER": "Card number missing.",
      "CARD_EXPIRY": "Expiry date missing.",
      "CARD_CVC": "CVC missing.",
    },
  },
  "COOKIE_CONSENT": {
    "ALL": "Allow all",
    "SELECTED": "Allow selection",
    "NECESSARY": "Allow necessary",
  },
  "COOKIE_OPTIONS": {
    "NECESSARY": "Necessary",
    "STATISTICAL": "Statistical",
    "MARKETING": "Marketing",
  },
  "VALIDATION": {
    "REQUIRED": "This field is mandatory.",
    "EMAIL": "Please enter a valid email address.",
    "errorInvalidEmail": "Please enter a valid email address.",
    "errorEmailExists": "This email is already in use by another account.",
    "PHONE": "Please enter a valid phone number.",
    "NUMERIC": "This is a numeric field.",
    "LENGTH_LT": "This field contains too many characters.",
    "POSTCODE": "Please enter a valid postal code.",
  },
  "STATUS_CODE": {
    /* Client */
    "UNKNOWN": "An unknown error occured. Please reload the page and try again.",
    "NETWORK_ERROR": "The network call was canceled. Please verify that you have an active internet connection and try again.",
    "PLACE_ORDER_CATCH": "An unknown error occured. Please try again later.",
    "DISCOUNT_CODE_APPLIED": "Discount code applied.",
    "DISCOUNT_CODE_FAILURE": "The discount code could not be applied. Please contact customer service if the problem persists.",

    "WISHLIST_VARIANT": "You must select a variant in order to add this product to your wishlist.",
    "WISHLIST_VARIANT_CARD": "This product comes in multiple variants. Please go to the product page and select a variant in order to add this product to your wishlist.",
    "errorUnknownProduct": "Unknown product. Please try again.",

    "errorNoPaymentMethod": "No payment method selected.",
    "errorProductQuantityNotAvailable": "The requested quantity is not currently available.",
    "errorProductNotInStock": "This product is currently out of stock.",
    "errorProductMaxQuantity": "We don't have the requested quantity in stock.",
    "errorProductNotChosenVariant": "Please select %attributeLabel in order to place this item in your cart.",
    "termsNotAccepted": "You must approve the terms and conditions to complete your order.",

    "errorNotLoggedIn": "You're not logged in. Please log in again.",
    "errorInvalidState": "An unknown error occured. Please try again.",
    "errorInvalidLogin": "Invalid login details.",
    "errorMissingCustomer": "The customer you are trying to login as does not exist.",
    "errorInsufficientScandicPointsForPurchase": "You don't have the points to complete this purchase.",
    "errorBlacklistedEmail": "This email is already in use by another account. Please use another one.",
    "errorInvalidEmail": "The email address is not correct, please check it.",
    "errorEmailExists": "This email is already in use by another account. Please use another one.",

    "Your card number is incomplete": "Please enter your card details.",

    "YOUR_CARD_HAS_INSUFFICIENT_FUNDS": "Insufficient funds on card.",
    "YOUR_CARDS_SECURITY_CODE_IS_INCORRECT": "The CVC code for this card is invalid.",
    "YOUR_CARD_WAS_DECLINED": "The card was declined.",
    "YOUR_CARD_HAS_EXPIRED": "The card has expired.",
    "AN_ERROR_OCCURRED_WHILE_PROCESSING_YOUR_CARD_TRY_AGAIN_IN_A_LITTLE_BIT": "An error occurred. Please try again later",
    "WE_ARE_UNABLE_TO_AUTHENTICATE_YOUR_PAYMENT_METHOD_PLEASE_CHOOSE_A_DIFFERENT_PAYMENT_METHOD_AND_TRY_AGAIN": "We could't authenticate your payment method. Please select an alternative payment method (if applicable) and try again.",
    "INTERNAL_SERVER_ERROR": "Internal server error.",
    "SCANDIC_CHECKOUT_TOKEN_IS_NO_LONGER_VALID": "The code has expired.",
  },
};
